import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import FeaturedList from "../components/common/featuredlist";
import Review from "../components/common/review";
import TowerAdventure from "../components/tower/TowerAdventure";
// import WidelifeSpotting from "../components/tower/WidelifeSpotting";
import YourGuides from "../components/common/yourGuides";
import BreadCrumb from "../components/common/breadcrumb";
import RegenerativePledge from "../components/common/regenerativePledge";
import Seo from "../components/seo";
import DegreeView from "../components/tower/DegreeView";
import SliderAndContent from "../components/common/SliderAndContent";
import Faqs from "../components/home/faq";

export default function Tower({
  data: { sanityTowerPage, sanitySitesettings },
}) {
  let seo;
  let hero;
  let breadcrumb;
  let features;
  let review;
  let adventure;
  let contentSlider;
  let guide;
  let regenerative;
  let faq;

  sanityTowerPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "featureList") {
      features = element;
    } else if (element._type === "reviewSection") {
      review = element;
    } else if (element._type === "towerAdvantureSection") {
      adventure = element;
    } else if (element._type === "contentAndSlider") {
      contentSlider = element;
    } else if (element._type === "guidesSection") {
      guide = element;
    } else if (element._type === "regenerative") {
      regenerative = element;
    } else if (element._type === "faq") {
      faq = element;
    }
  });

  return (
    <Layout>
      <Seo data={seo} />
      <Hero
        heroSection={hero}
        h2Size="text-3xl sm:text-[50px]"
        h2P="pb-6 pt-1"
      />

      <Logo logo={sanitySitesettings?.logo} />
      {features && (
        <div className="-m-5">
          <FeaturedList data={features} />
        </div>
      )}
      {review && <Review data={review} />}
      <br />
      {adventure && <TowerAdventure data={adventure} />}
      {contentSlider && (
        <SliderAndContent data={contentSlider} sliderId="tower-page" />
      )}
      {faq?.heading && faq?.faqList.length > 0 && <Faqs data={faq} />}

      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      {guide && <YourGuides data={guide} />}
      {sanityTowerPage?.degree && <DegreeView />}
      {regenerative && (
        <div>
          <RegenerativePledge data={regenerative} />
          <Logo logo={sanitySitesettings?.logo} />
        </div>
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityTowerPage {
      pageBuilder {
        ... on SanityFaq {
          _type
          heading
          faqList {
            question
            _rawAnswer
          }
        }
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          alignment
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityFeatureList {
          _type
          featureItems {
            icon
            title
          }
        }
        ... on SanityReviewSection {
          _type
          comment
          subHeading
          subHeading2
          heading
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
          ctaButton1 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
        }
        ... on SanityTowerAdvantureSection {
          _type
          heading
          videoUrl
          youtubeId
          coverImg {
            asset {
              gatsbyImageData(
                fit: FILLMAX
                placeholder: BLURRED
                formats: WEBP
                width: 770
              )
            }
          }
          ctaButton1 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
        }
        ... on SanityContentAndSlider {
          _type
          title
          bgRepeat
          bgSize
          direction
          height
          overlay
          size
          textColor
          paragraphTitle
          sectionBG {
            asset {
              url
            }
          }
          ctaButton1 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          sliderImages {
            alt
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
          _rawContent
          _rawContent2
        }
        ... on SanityGuidesSection {
          _type
          quote1
          firstQuote
          quote2
          secondQuote
          title
          sliderImage {
            guidance
            leader
            name
            photo {
              asset {
                _id
                metadata {
                  blurHash
                }
              }
              alt
              crop {
                bottom
                left
                right
                top
              }
              hotspot {
                x
                y
              }
            }
          }
        }
        ... on SanityRegenerative {
          _type
          heading
          _rawContent
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
        }
      }
      degree
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
